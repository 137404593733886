.HWCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  z-index: 0;
  transition: 0.2s;
}

.HWCard:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
}

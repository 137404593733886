.container {
  /* max-width: 99vw; */
  padding-top: 1rem;
}
.row {
  min-height: 90vh;
}
.searchCol {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
  background: white;
  margin-top: -1rem;
  padding-top: 1rem;
}

.resultsCol {
  height: 95vh;
  overflow: hidden;
  overflow-y: scroll;
  margin-top: -1rem;
  padding-top: 1rem;
}
.CartPane {
  z-index: 11;
}
.CartPaneAlt {
  width: 50% !important;
}
.formLabel {
  font-weight: bold;
  border-bottom: 1px solid lightgrey;
  width: 100%;
  display: block;
}
@media (max-width: 800px) {
  .CartPaneAlt {
    width: 100% !important;
  }
}

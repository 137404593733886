.NavBar {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 10;

  /* background: #021b79;
  background: linear-gradient(308deg, #0575e6 0%, #021b79 100%); */
}
.CartPane {
  z-index: 11;
}

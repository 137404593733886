.Footer {
  text-align: center;

  padding-top: 5rem;
  padding-bottom: 5rem;
  background: rgb(204, 0, 0);
  /* background: linear-gradient(
    180deg,
    rgba(204, 0, 0, 1) 0%,
    rgba(241, 194, 50, 1) 100%
  ); */
  color: white;
  border-radius: 5px 5px 0px 0px;
}
.a {
  color: white;
  font-weight: bold;
}
.a:hover {
  color: white;
  text-decoration: underline;
}
